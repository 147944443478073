import React from 'react'

const SvgPhone1 = props => (
  <svg width={21} height={21} {...props}>
    <path
      fillRule='evenodd'
      fill='#FD5C1F'
      d='M8.447 12.514c1.802 1.807 3.852 3.489 4.66 2.679 1.18-1.184 1.864-2.18 4.474-.124 2.609 2.056.621 3.489-.498 4.61-1.304 1.309-6.213.062-10.998-4.735C1.239 10.147-.004 5.225 1.301 3.916 2.419 2.795 3.786.801 5.899 3.355c2.113 2.555 1.056 3.303-.124 4.486-.808.81.87 2.866 2.672 4.673zm2.609-8.536s-.559-.062-.932.312-.435 1.059 0 1.495c.249.25.559.312.559.312.684.124 1.678.311 2.734 1.433 1.057 1.059 1.305 2.056 1.43 2.741 0 0 .062.374.31.561.435.436 1.057.374 1.492 0 .372-.374.31-.935.31-.935-.186-1.37-.87-2.803-1.988-3.925a7.627 7.627 0 00-3.915-1.994zm5.095.81c1.989 1.994 2.797 4.237 2.548 6.044 0 0-.124.561.249.935.435.436 1.056.373 1.491 0 .248-.25.311-.686.311-.686.248-1.557-.063-4.735-3.107-7.788C14.598.24 11.429-.009 9.876.178c0 0-.435.062-.684.311-.373.374-.435 1.06 0 1.496.373.374.932.249.932.249 1.802-.249 4.039.623 6.027 2.554z'
    />
  </svg>
)

export default SvgPhone1
